import MenuGroup from './MenuGroup';

const Menu = ({items, toggle}) => {
  return (
    <div>
      {items.map((menu, i) => 
          <MenuGroup key={i} toggle={toggle} group={menu}/>
        )
      }
    </div>
  )
}

export default Menu