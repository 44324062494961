import { UserMenuItems } from 'Components/Menu/user.config';
import { AdminMenuItems } from 'Components/Menu/admin.config';
import HomeMenu from 'Components/Menu/HomeMenu';
import { useOutletContext } from "react-router-dom";
import { useEffect } from 'react';
import useAuth from "Hooks/useAuth";

const Home = () => {
  const [setTitle] = useOutletContext();
  const auth = useAuth();

  useEffect(() => {
    setTitle('Главная');
  }, []);

  return (
    <div className='flex'>
      <HomeMenu items={UserMenuItems} />
      {auth.user?.is_staff && <HomeMenu items={AdminMenuItems}/>}
    </div>
  );
}

export default Home;