import InputLabel from 'Components/InputLabel';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { useForm } from "react-hook-form";
import { axiosPrivate } from 'Api/axios';
import { Link, useOutletContext } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ForgotPassword = () => {
    const [setTitle] = useOutletContext();
    const [message, setMessage] = useState(null);
    const {
        register,
        handleSubmit,
    } = useForm();

    const submit = (data) => {
        axiosPrivate.post('/forgot-password/?email=' + data.email, data).then((response) => {
            setMessage("На вашу почту выслано письмо с ссылкой для восстановления пароля")
        }).catch(function (error) {
            console.log(error);
        });
    };

    useEffect(() => {
        setTitle('Забыли пароль?');
    });

    return (
        <>
            <div className="mb-4 text-sm text-gray-600">
                Забыли пароль?
            </div>
            {message ?
                <div>{message}</div>
            :
                <form onSubmit={handleSubmit(submit)}>
                    <InputLabel htmlFor="email" value="Email" />
                    <input
                        id="email"
                        type="email"
                        name="email"
                        className="mt-1 block w-full"
                        {...register('email')}
                    />

                    <PrimaryButton className="mt-4">
                        Восстановить пароль
                    </PrimaryButton>

                </form>
            }
            <Link
                to='/login'
                className="underline text-sm mt-4 block text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Вход
            </Link>
        </>
    );
}

export default ForgotPassword;