import BoxedElement from "Components/BoxedElement"
import { Outlet, useOutletContext } from "react-router-dom";

export const GuestLayout = () => {
  const [setTitle] = useOutletContext();
  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen bg-slate-950">
        <img src="/logo.png" className='mb-3'/> 
        <BoxedElement className={'p-5'}><Outlet context={[setTitle]} /></BoxedElement>
      </div>
    </>
  )
}