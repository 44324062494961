import BoxedElement from "Components/BoxedElement";
import BoxedElementHeader from "Components/BoxedElementHeader";
import Modal from 'Components/Modal';
import PrimaryButton from "Components/Buttons/PrimaryButton";
import { useState, useEffect } from "react";
import Table from 'Components/Table/TableWrap';

import useApi from 'Hooks/useApi';
import { useOutletContext } from 'react-router-dom';

const Balance = () => {
  const [showBalanceChangeModal, setShowBalanceChangeModal] = useState(false);
  const [balance, setBalance] = useState(null);
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [setTitle] = useOutletContext();
  const api = useApi();

  const getData = async () => {
    try {
        const response = await api.get('settings/balance');
        setData(response.data);
        setBalance(response.data.currentBalance);
        setLoadingData(false);
    } catch (err) {
        console.error(err);
    }
  }

  useEffect(() => {
      setTitle('Баланс');
      getData();
  }, []);

  const balanceChangeModal = () => {
    setShowBalanceChangeModal(true);
  };

  const changebalance = (e) => {
    setBalance(e.target.value)
  }

  const closeModal = () => {
    setShowBalanceChangeModal(false);
  };

  const balanceReplenish = (e) => {
    e.preventDefault();
    // api.post(route('user.settings.payments.store'), {
    //   'amount': balance,
    //   'returnUrl': route('user.settings.payments.check')
    // }).then((response) => {
    //   window.location = response.data;
    // });
  }

  return (
    <>
      {!loadingData ?
        <>
          <BoxedElement>
            <BoxedElementHeader>Баланс</BoxedElementHeader>
            <div className="p-5">
              <div>{data.currentBalance}</div>
              <PrimaryButton className='mt-5 block' onClick={balanceChangeModal}>Пополнить</PrimaryButton>
              <Modal show={showBalanceChangeModal} onClose={closeModal}>
                <form className="p-5" onSubmit={balanceReplenish}>
                  <input 
                    type="number" 
                    defaultValue="" 
                    id='balance' 
                    name="balance"
                    className='mr-2'
                    onChange={changebalance}
                  />
                  <PrimaryButton type="submit" className='mt-5'>
                    Пополнить
                  </PrimaryButton>
                </form>
              </Modal>
            </div>
          </BoxedElement>
          <h2 className="mt-5 mb-2">История</h2>
          {data.balanceHistory ? 
            <div>
              <Table 
                className={'pt-4'}
                columns={
                  { 
                    amount: {
                      name: 'Сумма',
                      orderable: false,
                    },
                    description: {
                      name: 'Описание',
                      orderable: false,
                    },
                  }
                }
                dataUrl = 'settings/balance'
                data = 'balanceHistory'
                cells = {['amount', 'description']}
              />
            </div>
          :
            <>Нет транзакций по счету</>
          }
        </>
      :
        <p>Загрузка</p>
      }
    </>
  )
}

export default Balance