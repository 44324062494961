const TableHeader = ({columns, changeOrder}) => {
  return (
    <thead>
      <tr>
        {Object.keys(columns).map((col) => 
          <th 
            className={` px-4 text-left font-medium ${columns[col].orderable ? 'cursor-pointer' : ''}`}
            key={col}
            {...(columns[col].orderable && 
              {
                onClick: () => {
                  changeOrder(col);
                }
              }
            )}
            
          >
            {columns[col]?.name}
          </th>
        )}
      </tr>
    </thead>
  )
}

export default TableHeader