import BoxedElement from 'Components/BoxedElement';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import InputLabel from 'Components/InputLabel';
import { useForm } from "react-hook-form";
import useApi from 'Hooks/useApi';
import { useState } from 'react';
import useNotification from 'Hooks/useNotification';

const NewSubscription = ({closeModal}) => {
  const [formErrors, setFormErrors] = useState('');
  const {
    register,
    handleSubmit,
  } = useForm();
  const { setMessage } = useNotification();
  const api = useApi();

  const submit = (data) => {
    api.post('admin/subscriptions/', data).then((response) => {
      closeModal();
      setMessage("Добавлено");
    }).catch(function (error) {
      console.log(error);
      setFormErrors(error);
    });
  };

  return (
    <>
      <BoxedElement className={'p-5'}>
        <form onSubmit={handleSubmit(submit)}>
          <div className='mt-4'>
            <InputLabel>Название</InputLabel>
            <input type="text" name="name" {...register("name")} />
          </div>
          <div className='mt-4'>
            <InputLabel>Описание</InputLabel>
            <textarea name="description" {...register("description")}></textarea>
          </div>
          <div className='mt-4'>
            <InputLabel>Цена</InputLabel>
            <input type="text" name="price" {...register("price")}/>
          </div>
          <PrimaryButton className='mt-4'>Применить</PrimaryButton>
        </form>
      </BoxedElement>
    </>
  )
}

export default NewSubscription