import { useState, useEffect } from "react";
import BoxedElement from 'Components/BoxedElement';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import useApi from 'Hooks/useApi';
import Loader from 'Components/Loader';
import BoxedElementHeader from 'Components/BoxedElementHeader';
import { useOutletContext } from "react-router-dom";

const Services = () => {
    const [services, setServices] = useState(null);
    const api = useApi();
    const [loadingData, setLoadingData] = useState(true);
    const [setTitle] = useOutletContext();
    const getServices = async () => {
        setLoadingData(true);
        try {
            const servicesResponse = await api.get('/services/');
            setServices(servicesResponse.data.result);
        } catch (err) {
            console.error(err);
        } finally {
            setLoadingData(false);
        }
    }

    useEffect(() => {
        setTitle('Сервисы');
        getServices();
    }, [])
    
    const handleAttachService = async (alias) => {
        try {
            await api.post('/subscribe/'+alias+'/').then();
        } catch (error) {
            console.log(error);
        }
    }
    const handleDetachService = async (alias) => {
        try {
            await api.post('/unsubscribe/'+alias+'/').then();
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            {!loadingData ?
                services.length != 0 ?
                    <div className='flex'>
                        {
                            services.map((service) => 
                                <BoxedElement className={'w-3/12 text-center m-2 flex flex-col'} key={service.id}>
                                    <BoxedElementHeader>{service.name}</BoxedElementHeader>
                                        <div className='text-slate-400 mb-4 p-5'>{service.description}</div>
                                        <div className='p-5 mt-auto'>
                                            {
                                                service.is_subscribed ?
                                                    <PrimaryButton onClick={() => handleDetachService(service.alias)}>Отключить</PrimaryButton>
                                                :
                                                    <PrimaryButton onClick={() => handleAttachService(service.alias)}>Подключить</PrimaryButton>
                                            }
                                        </div>
                                </BoxedElement>
                            )
                        }
                    </div>
                :
                    <p>Нет сервисов</p>
            :
                <Loader></Loader>
            }
        </div>
    );
}

export default Services;