import HomeMenuGroup from "./HomeMenuGroup"

const HomeMenu = ({items}) => {

  return (
    <>
      {items.map((menu, i) => 
        <HomeMenuGroup key={i} group={menu}/>
      )}
    </>
  )
}

export default HomeMenu