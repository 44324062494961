import Table from 'Components/Table/TableWrap';
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import NewSubscription from './New';
import Modal from 'Components/Modal';

const Subscriptions = () => {
  const [showModal, setShowModal] = useState(false);
  const [setTitle] = useOutletContext();
  const [refresh, setRefresh] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setRefresh(true);
  }

  useEffect(() => {
    setTitle('Подписки');
    setRefresh(false);
  }, [refresh]);

  return (
    <>
      {!refresh &&
        <Table 
          filters={
            {
            id: 'id',
            name: 'Название'
            } 
          }
          columns={
              { 
              id: {
                name: 'id',
                orderable: true,
              },
              name: {
                name: 'Название',
                orderable: true,
              },
              description: {
                name: 'Описание',
                orderable: false,
              },
              price: {
                name: 'Цена', 
                orderable: false,
              },
              last: {
                name: ''
              }
            }
          }
          dataUrl = 'admin/subscriptions/'
          cells = {['id', 'name', 'description', 'price']}
          deleteItems = {true}
          editItems = {true}
        />
      }
      
      <PrimaryButton className='mt-5' onClick={() => setShowModal(true)}>
        Создать подписку
      </PrimaryButton> 
      <Modal show={showModal} onClose={closeModal}>
        <NewSubscription closeModal={closeModal} />
      </Modal>
    </>
  );
}

export default Subscriptions;