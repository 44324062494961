import { useEffect } from 'react';
import { useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import { axiosPrivate } from 'Api/axios';

export default function VerifyEmail() {
    const navigate = useNavigate();
    const [setTitle] = useOutletContext();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        setTitle('Подтверждение почты');
        let token = searchParams.get("token");
        axiosPrivate.get("/email-activation/?token="+token).then((response) => {
            console.log(response.data);
            setTimeout(() => {
                navigate('/login');
            }, 2000);
        }).catch(function (error) {
            console.log(error);
        });
    }, []);

    return (
        <>
            <div className="mb-4 text-sm text-gray-600">
                Почта подтверждена! Сейчас мы перенаправим Вас в личный кабинет
            </div>
        </>
    );
}
