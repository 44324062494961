import React from 'react'
import DeleteButton from 'Components/Buttons/DeleteButton';

const ServiceProperty = ({id, changeProperty, deleteProperty, property}) => {
  return (
    <div className='flex mt-2'>
      <input type="hidden" name={`property-${id}-new`}  value={property?.new} />
      <input 
        type="text"
        name={`property-${id}-name`} 
        placeholder="Название" 
        className={' w-1/3 mr-2'} 
        value={property?.name}
        onChange={(e) => changeProperty(id, 'name', e.target.value)}
        required
      />
      <input 
        name={`property-${id}-code`} 
        placeholder="Символьный код" 
        className={' w-1/3 mr-2'} 
        value={property?.code}
        type="text"
        onChange={(e) => changeProperty(id, 'code', e.target.value)}
        required
      />
      <input 
        name={`property-${id}-value`} 
        placeholder="Значение" 
        className={' w-1/3'} 
        type="text"
        value={property?.value}
        onChange={(e) => changeProperty(id, 'value', e.target.value)}
        required
      />
      <DeleteButton onClick={() => deleteProperty(id, property?.new, property?.id)}/>
    </div>
  )
}

export default ServiceProperty