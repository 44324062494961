import { useEffect, useState } from 'react';
import useMountTransition from 'Hooks/useMountTransition';
import useNotification from 'Hooks/useNotification';

const Notification = () => {
  const { message, setMessage } = useNotification();
  const [messageIsMounted, setMessageIsMounted] = useState((message) ? true : false);
  const hasTransitionedIn = useMountTransition(messageIsMounted, 800);

  useEffect(() => {
    if(message) {
      setMessageIsMounted(true);
    }

    const timer = setTimeout(() => {
      setMessageIsMounted(false);
      setMessage(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    }
  },[message]);

  return (
    (hasTransitionedIn || messageIsMounted) && (
      <div className={'fixed z-30 right-[40px] top-[40px] bg-slate-900 text-white py-4 px-8 rounded-xl shadow-lg animate-slide-top ' 
        + (hasTransitionedIn ? 'in ' : '')
        + (messageIsMounted ? 'visible' : 'out-transition')}
      >
        {message}
        <span className='absolute right-[10px] top-[10px] cursor-pointer' onClick={() => 
          {
            setMessageIsMounted(false);
            setMessage(false);
          }
        }>
          <svg xmlns="http://www.w3.org/2000/svg" height="10" width="7.5" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" fill="#fff"/></svg>
        </span>
      </div>
    )
  )

}

export default Notification