import InputError from 'Components/InputError';
import InputLabel from 'Components/InputLabel';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { Transition } from '@headlessui/react';
import DragAndDropInput from 'Components/DragAndDropInput';
import { useState, useEffect } from 'react';
import useAuth from "Hooks/useAuth";
import { useForm } from "react-hook-form";
import { axiosPrivate } from 'Api/axios';

export default function UpdateProfileInformation({ className = '' }) {
    const auth = useAuth();
    const user = useAuth().user;
    const [avatar, setAvatar] = useState();
    const [avatarPreview, setAvatarPreview] = useState();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit
    } = useForm();

    useEffect(() => {
        if(avatar) {
            let oFReader = new FileReader();
            oFReader.readAsDataURL(avatar);

            oFReader.onload = function (oFREvent) {
                setAvatarPreview(oFREvent.target.result);
            }
        }
    }, [avatar])

    const submit = (data) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("avatar", avatar);
        formData.append("full_name", data.full_name);
        axiosPrivate.patch("/settings/profile/"+user.id+"/", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            auth.setUser(response.data);
            setLoading(false);
        }).catch(function (error) {
            console.log(error);
            setLoading(false);
        });
    };

    return (
        <section className={className}>
            <form onSubmit={handleSubmit(submit)} encType="multipart/form-data" className="space-y-6">
                <div>
                    <InputLabel htmlFor="name" value="Аватар" />
                    {user?.avatar && 
                        <div className='w-[100px] h-[100px] rounded-full my-5'>
                            <img src={`https://lk.pixora.ru/api/media/${user.avatar}`} className='object-contain w-full h-full rounded-full'/>
                        </div>
                    }
                    <div className='flex items-center'>
                        <DragAndDropInput className={'w-5/12 mr-5'}  inputName="avatar" handleChangeProp={setAvatar} accept={'.jpg'}/>
                        <div className='w-[70px] h-[70px] rounded-full'>
                            <img src={avatarPreview} className='object-contain w-full h-full rounded-full'/>
                        </div>
                    </div>
                </div>
                <div>
                    <InputLabel htmlFor="name" value="Имя" />
                    <input
                        id="name"
                        type="text"
                        className="mt-1 block w-full"
                        autoComplete="name"
                        {...register("full_name", { value: user.full_name })}
                    />
                    <InputError className="mt-2" message={``} />
                </div>

                <div className="flex items-center gap-4">
                    <PrimaryButton>Сохранить</PrimaryButton>

                    <Transition
                        show={``}
                        enter="transition ease-in-out"
                        enterFrom="opacity-0"
                        leave="transition ease-in-out"
                        leaveTo="opacity-0"
                    >
                        <p className="text-sm text-gray-600">Сохранено</p>
                    </Transition>
                </div>
            </form>
        </section>
    );
}
