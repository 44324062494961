import InputLabel from 'Components/InputLabel';
import { useEffect, useState } from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { useForm } from "react-hook-form";
import { Link, useOutletContext } from 'react-router-dom';
import { axiosPrivate } from 'Api/axios';
import Loader from 'Components/Loader';

const Register = () => {
    const [setTitle] = useOutletContext();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const {
        register,
        handleSubmit,
    } = useForm();

    const submit = (data) => {
        setLoading(true);
        axiosPrivate.post("/register/", data).then((response) => {
            setMessage("Вы успешно зарегестрированы! На вашу почту выслано письмо с ссылкой для подтверждения аккаунта");
        }).catch(function (error) {
            console.log(error);
        });
        setLoading(false);
    };

    useEffect(() => {
        setTitle('Регистрация');
    });
    return (
        <>
            {message ?
                <div>{message}</div>
            :
                <form onSubmit={handleSubmit(submit)}>
                    <div>
                        <InputLabel htmlFor="first_name" value="ФИО" />
                        <input
                            id="full_name"
                            name="full_name"
                            type="text"
                            className="mt-1 block w-full"
                            autoComplete="full_name"
                            {...register("full_name")}
                        />
                    </div>

                    <div className="mt-4">
                        <InputLabel htmlFor="email" value="Email" />
                        <input
                            id="email"
                            type="email"
                            name="email"
                            className="mt-1 block w-full"
                            autoComplete="username"
                            {...register("email")}
                        />
                    </div>

                    <div className="mt-4">
                        <InputLabel htmlFor="password" value="Пароль" />
                        <input
                            id="password"
                            type="password"
                            name="password"
                            className="mt-1 block w-full"
                            autoComplete="new-password"
                            {...register("password")}
                        />
                    </div>

                    <div className="mt-4">
                        <InputLabel htmlFor="password_confirmation" value="Подтверждение пароля" />
                        <input
                            id="password_confirmation"
                            type="password"
                            name="password_confirmation"
                            className="mt-1 block w-full"
                            autoComplete="new-password"
                            {...register("confirm_password")}
                        />
                    </div>

                    <PrimaryButton className="w-full mb-4 mt-4">
                        Регистрация
                    </PrimaryButton>
                    <Link
                        to="/login"
                        className="underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Уже зарегестрированы?
                    </Link>
                </form>
            }
            {loading && <Loader></Loader>}
        </>
    );
}

export default Register;