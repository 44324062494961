import { Link } from 'react-router-dom';

const TablePagination = ({pages, page, setPage}) => {
  return (
    <div className='flex justify-center'>
      {
        Array.from({ length: pages }).map((i, index) => {
          ++index;
          return (
            <Link 
              key={index}
              className={`mx-2 p-2 rounded border ${index == page ? ' border-gray-100 bg-zinc-200' : 'border-transparent'}`} 
              onClick={() => setPage(index)}
            >{index}</Link>
          );
        })
      }
    </div>
  )
}

export default TablePagination