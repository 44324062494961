import React from 'react'

const ListElement = ({className, children}) => {
  return (
    <li className={`flex users-list py-2 px-5 border-b border-gray-100 text-base ${className}`}>
      {children}
    </li>
  )
}

export default ListElement