import { useLocation, Navigate, Outlet, useOutletContext } from "react-router-dom";
import useAuth from "../Hooks/useAuth";
import { AuthentificatedLayout } from './AuthentificatedLayout';
import { useState, useEffect } from "react";
import useRefreshToken from "../Hooks/useRefreshToken";
import Loader from "Components/Loader";

const PrivateRoute = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const auth = useAuth();
  const location = useLocation();
  const [setTitle] = useOutletContext();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      }
      catch (err) {
        console.error(err);
      }
      finally {
        isMounted && setIsLoading(false);
      }
    }

    !auth?.token ? verifyRefreshToken() : setIsLoading(false);

    return () => isMounted = false;
  }, [isLoading])

  return (
    <AuthentificatedLayout>
      {      
        isLoading
          ? <Loader></Loader>
          : auth.token
            ? <Outlet context={[setTitle]} />
            : <Navigate to="/login" state={{ from: location }} replace />
      }
    </AuthentificatedLayout>
  );
};

export default PrivateRoute;