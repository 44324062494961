import { useState, useEffect } from "react";
import BoxedElement from 'Components/BoxedElement';
import BoxedElementHeader from 'Components/BoxedElementHeader';
import useApi from 'Hooks/useApi';
import Loader from 'Components/Loader';
import { Link, useOutletContext } from 'react-router-dom';

const UserServices = () => {
    const [data, setData] = useState();
    const [loadingData, setLoadingData] = useState(true);
    const [setTitle] = useOutletContext();
    const api = useApi();

    const getServices = async () => {
        try {
            const response = await api.get('my-services/');
            setData(response.data.result);
            setLoadingData(false);
        } catch (err) {
            console.error(err);
        }
    }
    
    useEffect(() => {
        setTitle('Мои сервисы');
        getServices();
    }, []);

    return (
        <div className='flex'>
            {!loadingData ?
                    data.map((service) => 
                        <Link key={service.id} href={`services/${service.id}/`}  className='w-3/12 text-center m-2'>
                            <BoxedElement>
                                <BoxedElementHeader>{service.name}</BoxedElementHeader>
                                <div className='p-5'>
                                    <p className='text-slate-400 mb-4'>{service.description}</p>
                                    <p>До {service?.end_date}</p>
                                </div>
                            </BoxedElement>
                        </Link>
                    )
                :
                    <Loader></Loader>
            }
        </div>
    );
}

export default UserServices;
