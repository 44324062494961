import React, { useEffect, useState } from 'react';
import NavLink from '../../Components/NavLink';

const MenuGroup = ({toggle, group}) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if(toggle) setExpanded(false);
  }, [toggle])
  
  return (
    <div>
      <div className='flex text-white px-3 py-4 cursor-pointer items-center' onClick={() => setExpanded((prev) => !prev)}>
        <i className='mr-1 w-[20px]'>
          <div dangerouslySetInnerHTML={{__html: group.icon}} />
        </i>
        {!toggle && group.name}
        {!toggle && 
          <div className={`ml-auto transition-all duration-100 ${expanded ? "rotate-180" : "rotate-0"}`} >
            <i className={`${expanded ? "rotate-180" : "rotate-0"}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="12" width="14"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" fill="#fff"/></svg>
            </i>
          </div>
        }
      </div>
      {group.childrens && 
        <div className={`bg-slate-900 overflow-hidden transition-all duration-300 ${expanded ? "max-h-52" : "max-h-0"}`}>
          {
            group.childrens.map((child, j) => {
              //var hrefChild = child.individual ? route(child.href, auth.user.id) : route(child.href);
              var hrefChild = child.href;
              return (
                <NavLink 
                  className='pl-[40px] text-sm'
                  key={j} 
                  to={hrefChild}
                >
                  {child.name}
                </NavLink>
              )
            })
          }
        </div>
      }
    </div>
  )
}

export default MenuGroup;