
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css'

import Home from 'Pages/Home';

import Login from 'Pages/Auth/Login';
import Register from 'Pages/Auth/Register';
import ForgotPassword from 'Pages/Auth/ForgotPassword';

import Services from 'Pages/Services/Index';
import UserServices from 'Pages/User/Services/Index';
import Settings from 'Pages/User/Settings/Index';
import Balance from 'Pages/User/Settings/Balance';
import Payments from 'Pages/User/Settings/Payments';

import Notifications from 'Pages/User/Notifications/Index';
import ShowNotification from 'Pages/User/Notifications/Show';

import Users from 'Pages/Admin/Users/Index';
import UsersEdit from 'Pages/Admin/Users/Edit';

import AdminServices from 'Pages/Admin/Services/Index';
import EditService from 'Pages/Admin/Services/Edit';

import ServicesStatistic from 'Pages/Admin/Services/Statistic/Index';

import PrivateRoute from 'Layouts/privateRoute';
import { GuestLayout } from 'Layouts/GuestLayout';

import AppLayout from 'Layouts/AppLayout';

import { Routes, Route } from 'react-router-dom';
import VerifyEmail from 'Pages/Auth/VerifyEmail';
import AdminRoute from 'Layouts/adminRoute';
import ResetPassword from 'Pages/Auth/ResetPassword';

import Subscriptions from 'Pages/Admin/Subscriptions/Index';
import EditSubscription from 'Pages/Admin/Subscriptions/Edit';
function App() {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Home />}/>

          <Route path="services" element={<Services />} />
          <Route path="user/services" element={<UserServices />} />
          <Route path="settings" element={<Settings />} />
          <Route path="balance" element={<Balance />} />
          <Route path="payments" element={<Payments />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="notifications/:id" element={<ShowNotification />} />

          <Route path="admin" element={<AdminRoute />}>
            <Route path="users" element={<Users />}/>
            <Route path="users/:id" element={<UsersEdit />}/>

            <Route path="services" element={<AdminServices />}/>
            <Route path="services/:id" element={<EditService />}/>
            <Route path="services/statistic" element={<ServicesStatistic />}/>
            
            <Route path="subscriptions" element={<Subscriptions />}/>
            <Route path="subscriptions/:id" element={<EditSubscription />}/>
          </Route>
        </Route>

        <Route element={<GuestLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot_password" element={<ForgotPassword />} />
          <Route path="email-activation" element={<VerifyEmail />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;