import React from 'react'
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
  const [title, setTitle] = useState('');

  return (
    <div className="main flex flex-col items-center justify-center h-screen bg-slate-950">
      <Helmet>
        <title>{title} - ЛК PIXORA</title>
      </Helmet>
      <Outlet context={[setTitle]}/>
    </div>
  )
}

export default AppLayout