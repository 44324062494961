const TableFilter = ({filters, search, query, setQuery}) => {

  if(filters) {
    return (
      <div className='px-4 mt-4 mb-4'>
        <h2>Фильтры</h2>
        <div className={'flex items-center flex-wrap'}>
          {
            Object.keys(filters).map((col, i) => {
              return (
                <input 
                  className={"mr-5 mt-2"} 
                  type="text"
                  key={i}
                  value={query[filters[col]]?.value}
                  placeholder={filters[col]} 
                  onKeyUp={(e) => {
                    setQuery({
                      name: col,
                      value: e.target.value
                    });
                    search()
                  }}
                  onChange={(e) => {
                    setQuery({
                      name: col,
                      value: e.target.value
                    });
                    search()
                  }}
                />
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default TableFilter