import { UserMenuItems } from 'Components/Menu/user.config';
import { AdminMenuItems } from 'Components/Menu/admin.config';
import Menu from 'Components/Menu/Menu';
import HeaderLayout from './HeaderLayout';
import React, { useState } from 'react';
import Logo from 'Components/Logo';
import useAuth from "Hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const AuthentificatedLayout = ({children}) => {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const toggleMenu = () => {
    setToggle(prev => !prev);
  }

  return (
    <div className="min-h-screen flex w-full">
      <div className={'left bg-slate-900 transition-all duration-100 ' + (toggle ? ' w-[40px]' : ' w-[200px]')}>
        <div className='left-head h-16 flex justify-center items-center drop-shadow border-b border-zinc-600'>
          {
            toggle ?
              <></>
            :
              <Logo/>
          }
          <div 
            className={`cursor-pointer transition-all duration-100 absolute right-1 w-[34px] flex justify-center ${toggle ? 'rotate-180' : '' }`} 
            onClick={toggleMenu}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="10px" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" fill="#fff"/></svg>
          </div>
        </div>
        <div className="flex flex-col min-h-80">
          <div className='mt-5 ml-3 text-white text-sm font-thin'>Меню</div>
          <Menu items={UserMenuItems} toggle={toggle}/>
          {auth.user?.is_staff && <div className='mt-5 ml-3 text-white text-sm font-thin'>Администратор</div>}
          {auth.user?.is_staff && <Menu items={AdminMenuItems} toggle={toggle}/>}
        </div>
      </div>
      <div className={`right bg-zinc-50 w-full ${toggle ? 'toggled' : ' '}`}>
        <HeaderLayout layoutType={'user'} toggleMenu={toggleMenu} toggle={toggle}/>
        <div className={`content p-5 duration-100 relative h-full`}>
          <div className={`pb-2 text-slate-400 cursor-pointer text-sm`} onClick={() => navigate(-1)}>{`<`} Назад</div>
          {children}
        </div>
      </div>
    </div>
  )
}
