import InputLabel from 'Components/InputLabel';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { useForm } from "react-hook-form";
import { axiosPrivate } from 'Api/axios';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Loader from 'Components/Loader';

const ResetPassword = () => {    
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);
    const {
        register,
        handleSubmit,
    } = useForm();

    const submit = (data) => {
        setLoading(true);
        let token = searchParams.get("token");
        axiosPrivate.post("/reset-password/?token="+token, data).then((response) => {
            setMessage("Вы успешно изменили пароль!");
        }).catch(function (error) {
            console.log(error);
        });
        setLoading(false);
    };

    return (
        <>
            {message ?
                <>     
                <div>{message}</div>       
                    <Link
                        to='/login'
                        className="underline text-sm block text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Войти
                    </Link>
                </>
            :
                <form onSubmit={handleSubmit(submit)}>
                    <div>
                        <InputLabel htmlFor="password" value="Новый пароль" />

                        <input
                            id="password"
                            type="password"
                            name="password"
                            className="mt-1 block w-full"
                            autoComplete="new-password"
                            {...register("new_password")}
                        />
                    </div>

                    <div className="mt-4">
                        <InputLabel htmlFor="password_confirmation" value="Подтверждение пароля" />

                        <input
                            type="password"
                            name="password_confirmation"
                            className="mt-1 block w-full"
                            autoComplete="new-password"
                            {...register("confirm_password")}
                        />
                    </div>

                    <PrimaryButton  className="mt-4 w-full">
                        Сбросить пароль
                    </PrimaryButton>
                </form>
            }
            {loading && <Loader></Loader>}
        </>
    );
}

export default ResetPassword;