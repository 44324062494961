import { axiosPrivate } from 'Api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const auth = useAuth();

    const refresh = async () => {

      let refresh = localStorage.getItem("refresh");
      let postData = {
        "grant_type":"refresh_token",
        "client_id":"1",
        "refresh_token": refresh
      }
      
      await axiosPrivate.post('/token/', postData).then((response) => {
        auth.loginAction(response.data);
      }).catch(function (error) {
        auth.logOut();
        console.log(error);
      });
    }

    return refresh;
};

export default useRefreshToken;