const BoxedElementHeader = ({className, children, ...props}) => {
  return (
    <div
      {...props}
      className={`px-5 py-2 mt-4 uppercase font-medium ${className}`}
    >
      {children}
    </div>
  )
}

export default BoxedElementHeader