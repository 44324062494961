import Table from 'Components/Table/TableWrap';
import { useOutletContext } from "react-router-dom";
import { useEffect, useState } from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import Modal from 'Components/Modal';
import NewService from './New';

const Services = () => {
    const [showModal, setShowModal] = useState(false);
    const [setTitle] = useOutletContext();
    const [refresh, setRefresh] = useState(false);

    const closeModal = () => {
        setShowModal(false);
        setRefresh(true);
    }

    useEffect(() => {
        setTitle('Сервисы');
        setRefresh(false);
    }, [refresh]);
    return (
        <>
            {!refresh &&
                <Table 
                    filters={
                        {
                        id: 'id',
                        alias: 'alias',
                        name: 'Название'
                        } 
                    }
                    columns={
                        { 
                            id: {
                                name: 'id',
                                orderable: true,
                            },
                            name: {
                                name: 'Название',
                                orderable: true,
                            },
                            description: {
                                name: 'Описание',
                                orderable: false,
                            },
                            alias: {
                                name: 'Alias', 
                                orderable: false,
                            },
                            last: {
                                name: ''
                            }
                        }
                    }
                    dataUrl = 'admin/services/'
                    cells = {['id', 'name', 'description', 'alias']}
                    deleteItems = {true}
                    editItems = {true}
                />
            }
            <PrimaryButton className='mt-5' onClick={() => setShowModal(true)}>
                Создать сервис
            </PrimaryButton> 
            <Modal show={showModal} onClose={closeModal}>
                <NewService closeModal={closeModal} />
            </Modal>
        </>
    );
}

export default Services;