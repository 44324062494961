import { useEffect, useState } from 'react';
import useApi from 'Hooks/useApi';
import { useNavigate } from 'react-router-dom';

const UserNotifications = () => {
  const [show, setShow] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const api = useApi();

  useEffect(() => {
    getNotifications();
  }, [show]);

  const getNotifications = async () => {
    try {
      const response = await api.get("notifications/unread/");
      setNotifications(response.data.result);
      setCount(response.data.result.length);
    } catch (err) {
      console.error(err);
    }
  }

  const showNotifications = () => {
    setShow((prev) => !prev);
  };

  const readAll = async () => {
    try {
      await api.patch("notifications/read_all/");
      getNotifications();
    } catch (err) {
      console.error(err);
    }
  };

  const toAll = () => {
    setShow(false);
    navigate('notifications');
  }

  return (
    <div className='notifications mr-5'>
      <div className='cursor-pointer notifications-button' onClick={() => showNotifications()}>
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
          <path d="M 12 2 C 11.172 2 10.5 2.672 10.5 3.5 L 10.5 4.1953125 C 7.9131836 4.862095 6 7.2048001 6 10 L 6 16 L 4.4648438 17.15625 L 4.4628906 17.15625 A 1 1 0 0 0 4 18 A 1 1 0 0 0 5 19 L 12 19 L 19 19 A 1 1 0 0 0 20 18 A 1 1 0 0 0 19.537109 17.15625 L 18 16 L 18 10 C 18 7.2048001 16.086816 4.862095 13.5 4.1953125 L 13.5 3.5 C 13.5 2.672 12.828 2 12 2 z M 10 20 C 10 21.1 10.9 22 12 22 C 13.1 22 14 21.1 14 20 L 10 20 z"></path>
        </svg>
        {
          count > 0 && <span>{count}</span>
        }
      </div>
      <div className={`notifications-container ${show ? 'active': ''}`}>
        {
          notifications.length > 0 
          ?
            notifications.map((notification) => 
              <div className='notification-item' key={notification.id}>
                <div>{notification.text}</div>
                <span>{new Date(notification.date).toLocaleDateString() + ' ' + new Date(notification.date).toLocaleTimeString()}</span>
              </div>
            )
          :
            <p>Нет непрочитанных оповещений</p>
        }
        {notifications.length > 0 && <div className='read-all' onClick={() => readAll()}>Прочитать всё</div>}
        <div className='read-all' onClick={() => toAll()}>Все оповещения</div>
      </div>
    </div>

  )

}

export default UserNotifications