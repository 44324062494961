import TransparentButton from '../Components/Buttons/TransparentButton';
import useAuth from "../Hooks/useAuth";
import UserNotifications from 'Components/UserNotifications';
const HeaderLayout = () => {
  const auth = useAuth();
  
  return (
    <nav className="bg-white px-5 flex items-center h-16  border-b border-zinc-200">
      <div className='ml-auto flex items-center justify-items-end'>
        {auth.user?.avatar && 
          <div className='w-[32px] h-[32px] rounded-full mr-3'>
            <img src={`https://lk.pixora.ru/api/media/${auth.user?.avatar}`} alt="Аватар" className='object-cover w-full h-full rounded-full w-[32px] h-[32px]'/>
          </div>
        }
        <div className=' mr-5 whitespace-nowrap'>{auth.user?.full_name}</div>
        
        <UserNotifications/>

        <TransparentButton onClick={() => auth.logOut()} method="post" as="button">
          <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="16"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z"/></svg>
          Выход
        </TransparentButton>
      </div>
    </nav>
  )
}

export default HeaderLayout