import BoxedElement from 'Components/BoxedElement';
import TableFilter from './TableFilter';
import TableHeader from './TableHeader';
import TablePagination from './TablePagination';
import TableCell from './TableCell';
import { useState, useEffect } from "react";
import useNotification from 'Hooks/useNotification';
import useApi from 'Hooks/useApi';
import EditButton from 'Components/Buttons/EditButton';
import DeleteButton from 'Components/Buttons/DeleteButton';
import Skeleton from 'react-loading-skeleton';

const TableWrap = ({data = false, filters, columns, children, cells, deleteItems = false, editItems = false, dataUrl, ...props}) => {
  const [tableData, setTableData] = useState(null);
  const { message, setMessage } = useNotification();
  const [order, setOrder] = useState('');
  const [url, setUrl] = useState(dataUrl);
  const [page, setPage] = useState(1);
  const [loadingData, setLoadingData] = useState(true);
  const [query, setQuery] = useState([]);

  const api = useApi();

  const getData = async () => {
    let pageUrl = (page > 1) ? '?page='+page : '';
    try {
      const response = await api.get(url+pageUrl);
      setTableData(response.data);
      setLoadingData(false);
    } catch (err) {
      console.error(err);
    }
  }

  const search = async () => {
    if(query.value) {
      try {
        const response = await api.get(
          url + '?'+query.name+'='+query.value
        );
        setTableData(response.data);
      } catch (err) {
        console.error(err);
      }
    } else {
      getData();
    }
  }
  
  const changeOrder = async (col) => {
    setOrder((prev) => prev == '-' ? '' : '-');

    try {
      const response = await api.get(
        url + '?ordering='+order+col
      );
      setTableData(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  const deleteItem = async (id) => {
    try {
      const response = await api.delete(
        url + '/' + id + '/'
      );
      setMessage(response.data)
    } catch (err) {
      console.error(err);
    }
    getData();
  }

  useEffect(() => {
    getData();
  }, [url, page]);

  return (
    <BoxedElement className={'w-full ' + props.className}>
      <TableFilter filters={filters} search={search} query={query} setQuery={setQuery} />
      {!loadingData ?
        tableData?.result && tableData?.result.length != 0 ?
          <>
            <div className='overflow-auto'>
              <table className='w-full'>
                <TableHeader columns={columns} url={url} changeOrder={changeOrder}/>
                <tbody>
                  {
                    tableData.result.map((item, i) => {
                      return(
                        <tr key={i} className='even:bg-gray-50 odd:bg-white'>
                          {
                            cells.map((cell, i) => {
                              return(
                                <TableCell key={i}>
                                  {
                                    (item[cell] instanceof Object) ?
                                      Object.keys(item[cell]).map((value, h) => {
                                        return (
                                          <div key={h}>{item[cell][h]}</div>
                                        )
                                      })
                                    :
                                      item[cell]
                                  }
                                </TableCell>
                              )
                            })
                          }
                          {
                            deleteItems || editItems ?
                              <TableCell>
                                <div className='flex'>
                                  {deleteItems && <EditButton link={`/${dataUrl}${item.id}`} className={'pt-1'}/>}
                                  {editItems && <DeleteButton onClick={() => deleteItem(item.id)} />}
                                </div>
                              </TableCell>
                            :
                              <></>
                          }
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
            {tableData?.pagination.pages && tableData?.pagination.pages != 1 && <TablePagination pages={tableData?.pagination.pages} page={page} setPage={setPage}/>} 
          </>
        :
          <>Нет данных</>
      :
        <>
          <div className='px-4 mt-4 mb-4'>
            <Skeleton width={100} height={15}/>
            <div className={'flex items-center flex-wrap w-full'}>
              <Skeleton className={"mr-5 mt-2 w-1/3"} width={200} height={30}/>  
              <Skeleton className={"mr-5 mt-2 w-1/3"} width={200} height={30}/> 
              <Skeleton className={"mr-5 mt-2 w-1/3"} width={200} height={30}/> 
            </div>
          </div>
          <div className='overflow-auto'>
            <table className='w-full'>
              <tbody>
                {Array.apply(0, Array(15)).map(function (x, i) {
                  return <tr key={i}>
                    <td className={`px-4 py-2`}><Skeleton height={24}/></td>
                    <td className={`px-4 py-2`}><Skeleton height={24}/></td>
                    <td className={`px-4 py-2`}><Skeleton height={24}/></td>
                    <td className={`px-4 py-2`}><Skeleton height={24}/></td>
                  </tr>;
                })}
              </tbody>
            </table>
          </div>
          {tableData?.pagination && data?.pagination.pages != 1 && <TablePagination links={tableData?.pagination?.links} page={page} setPage={setPage}/>} 
        </>
      }
    </BoxedElement>
  )
}

export default TableWrap