import { useState, useEffect } from "react";
import { useParams } from 'react-router';
import Loader from 'Components/Loader';
import BoxedElement from 'Components/BoxedElement';
import useApi from 'Hooks/useApi';
import { useOutletContext} from 'react-router-dom';

const ShowNotification = () => {
  const { id } = useParams();
  const api = useApi();
  const [setTitle] = useOutletContext();
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);

  const getData = async () => {
    try {
      const response = await api.get('notifications/'+id);
      setData(response.data);
      console.log(response.data);
    } catch (err) {
      console.error(err);
    }
    
    setLoadingData(false);
  }

  useEffect(() => {
    setTitle('Оповещения');
    getData();
  }, []);

  return (
    <>
      {!loadingData ?
          <>
            <BoxedElement className={'p-5'}>
              <div>{data.text}</div>
              <span>{new Date(data.date).toLocaleDateString() + ' ' + new Date(data.date).toLocaleTimeString()}</span>
            </BoxedElement>
          </>
        :
          <Loader></Loader>
      }
    </>
  )
}

export default ShowNotification