import Table from 'Components/Table/TableWrap';

const Payments = () => {
  return (
    <>
      <Table 
        className={'pt-4'}
        columns={
          { 
            id: {
              name: 'ID',
              orderable: false,
            },
            amount: {
              name: 'Сумма',
              orderable: false,
            },
            status: {
              name: 'Статус',
              orderable: false,
            },
            created_at: {
              name: 'Дата',
              orderable: false,
            },
          }
        }
        dataUrl = 'settings/payments'
        data = 'payments'
        cells = {['id', 'amount', 'status', 'created_at']}
      />
    </>
  )
}

export default Payments