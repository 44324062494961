import React, { useState, useEffect } from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import BoxedElement from 'Components/BoxedElement';
import BoxedElementHeader from 'Components/BoxedElementHeader';
import ListElement from 'Components/ListElement';
import InputLabel from 'Components/InputLabel';
import { useParams } from 'react-router';
import Modal from 'Components/Modal';
import useApi from 'Hooks/useApi';
import { useForm } from "react-hook-form";
import useNotification from 'Hooks/useNotification';

const EditUser = () => {
  const { id } = useParams();
  const [user, setUser] = useState([]);
  const [services, setServices] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [userServices, setUserServices] = useState(null);
  const [attachServiceModalShow, setAttachServiceModalShow] = useState(false);
  const { setMessage } = useNotification();
  const [formErrors, setFormErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const api = useApi();

  const {
    register,
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      full_name: "",
      email: "",
      balance: ""
    },
    user
  });

  const getData = async () => {
    setLoading(true);
    try {
      const response = await api.get("admin/users/"+id+"/");
      setUser(response.data);
      console.log(response.data);
      reset(response.data);
      getUserServices();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const getServices = async () => {
    try {
      const servicesResponse = await api.get('/services/');
      setServices(servicesResponse.data.result);
    } catch (err) {
      console.error(err);
    }
  }

  const getSubscriptions = async () => {
    try {
      const response = await api.get('admin/subscriptions/');
      setSubscriptions(response.data.result);
    } catch (err) {
      console.error(err);
    }
  }

  const getUserServices = async () => {
    try {
      let services = {};
      const servicesResponse = await api.get('admin/users/'+id+'/services/');
      for(let i = 0; i < servicesResponse.data.result.length; i++) {
        services[servicesResponse.data.result[i].id] = servicesResponse.data.result[i];
      };
      setUserServices(services);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getData();
    getServices();
    getSubscriptions();
  }, []);

  const submit = (data) => {
    console.log(data);
    if(data["password"].length === 0) delete data["password"];
    if(data["confirm_password"].length === 0) delete data["confirm_password"];
    if(data["subscription"]?.length === 0) delete data["subscription"];
    api.patch("admin/users/"+id+"/", data).then((response) => {
      setMessage("Добавлено");
    }).catch(function (error) {
      console.log(error);
      setFormErrors(error);
    });
  };

  const renewService = (service) => {
    api.post("admin/users/"+id+"/services/subscribe/", {
      "alias": service
    }).then((response) => {
      console.log(response);
      getData();
      setMessage("Подключено");
    }).catch(function (error) {
      console.log(error);
      setFormErrors(error);
    });
  }

  const attachService = (service) => {
    api.post("admin/users/"+id+"/services/subscribe/", {
      "alias": service
    }).then((response) => {
      console.log(response);
      getData();
      setMessage("Подключено");
    }).catch(function (error) {
      console.log(error);
      setFormErrors(error);
    });
  }

  const detachService = (service) => {
    api.post("admin/users/"+id+"/services/unsubscribe/", {
      "alias": service
    }).then((response) => {
      getData();
      setMessage("Отключено");
    }).catch(function (error) {
      console.log(error);
      setFormErrors(error);
    });
  }

  return (
    <>
      <form  onSubmit={handleSubmit(submit)}>
        <div className='flex flex-wrap justify-between'>
          <BoxedElement className={'w-1/2'}>
            <BoxedElementHeader>Данные</BoxedElementHeader>
            <div className='p-5'>
              <div>
                <InputLabel>ФИО</InputLabel>
                {!loading && <input type="text" name="name" {...register("full_name")} />}
              </div>
              <div className='mt-4'>
                <InputLabel>email</InputLabel>
                {!loading && <input type="text" name="email" {...register("email")} />}
              </div>
              <div className='mt-4'>
                <InputLabel>Новый пароль</InputLabel>
                {!loading && <input type="text" name="password" {...register("password")} />}
              </div>
              <div className='mt-4'>
                <InputLabel>Подтверждение пароля</InputLabel>
                {!loading && <input type="text" name="confirm_password" {...register("confirm_password")} />}
              </div>
            </div>
          </BoxedElement>
          <BoxedElement className={'w-1/2'}>
            <BoxedElementHeader>Подписка</BoxedElementHeader>
            <div className='p-5'>
              {subscriptions && 
                <select name="subscription" {...register("subscription")} >
                  {!user.subscription && <option value="">Не выбрано</option>}
                  {subscriptions.map((subscription) => 
                    <option value={subscription.id} selected={(subscription.id === user.subscription) ? "selected" : ""}>{subscription.name}</option>
                  )}
                </select>
              }

            </div>
            <BoxedElementHeader>Баланс</BoxedElementHeader>
              <div className='p-5'>
                <input
                  type="text"
                  id='balance' 
                  name="balance"
                  className='mr-2'
                  {...register("balance")}
                />
              </div>
          </BoxedElement>
        </div>
        <PrimaryButton type="submit" className='mt-5'>
          Применить
        </PrimaryButton>
      </form>
      <BoxedElement className={'w-full mt-5 relative'}>
        <BoxedElementHeader>Сервисы</BoxedElementHeader>
        {
        userServices &&
          <ul className='p-5 '>
            {
              services.map((service) =>
                <ListElement key={service.id} className="flex justify-between items-center !px-0">
                  <div className='w-6/12'>{service.name}</div>
                    {
                      userServices[service.id].is_subscribed ?
                        <div className='w-6/12 flex justify-end items-center'>
                          <div className='mr-5'>До {userServices[service.id]?.end_time}</div>
                          <PrimaryButton type="submit" className="mr-5" onClick={e => {
                            e.preventDefault();
                            renewService(service.alias)}
                          }>
                            Продлить
                          </PrimaryButton>
                          <PrimaryButton type="submit" className="bg-red-500" onClick={e => {
                            e.preventDefault();
                            detachService(service.alias)}
                          }>
                            Отключить
                          </PrimaryButton>
                        </div>
                      :
                      <div className='w-6/12 flex justify-end items-center'>
                        <PrimaryButton type="submit" onClick={e => {
                          e.preventDefault();
                          attachService(service.alias)}
                        }>
                          Подключить
                        </PrimaryButton>
                      </div>
                    }
                </ListElement>
              )
            }
          </ul>
        }
      </BoxedElement>
    </>
  )
}

export default EditUser;