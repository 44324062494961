import React, { useState, useEffect } from 'react';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import BoxedElement from 'Components/BoxedElement';
import InputLabel from 'Components/InputLabel';
import { useParams } from 'react-router';
import useApi from 'Hooks/useApi';
import { useForm } from "react-hook-form";
import useNotification from 'Hooks/useNotification';

const EditSubscription = () => {
  const { id } = useParams();
  const api = useApi();
  const { setMessage } = useNotification();
  const [subscription, setSubscription] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState('');

  const {
    register,
    handleSubmit,
    reset
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      price: ""
    },
    subscription
  });

  const getData = async () => {
    setLoading(true);
    try {
      const response = await api.get("admin/subscriptions/"+id+"/");
      setSubscription(response.data);
      reset(response.data);
      console.log(response.data)
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const submit = (data) => {
    api.patch("admin/subscriptions/"+id+"/", data).then((response) => {
      setMessage("Подписка изменена");
    }).catch(function (error) {
      console.log(error);
      setFormErrors(error);
    });
  };

  return (
    <>
      <BoxedElement>
        <form onSubmit={handleSubmit(submit)} className='p-5'>
          <div className="relative z-0 w-full mb-3 group">
              <InputLabel>Название</InputLabel>
              {!loading && <input type="text" name="name" {...register("name")} />}
          </div>
          <div className="relative z-0 w-full mb-3 group">
              <InputLabel>Описание</InputLabel>
              {!loading && <input type="text" name="name" {...register("description")} />}
          </div>
          <div className="relative z-0 w-full group">
              <InputLabel>Стоимость</InputLabel>
              {!loading && <input type="text" name="name" {...register("price")} />}
          </div>
          <PrimaryButton type="submit" className='mt-5'>
              Отправить
          </PrimaryButton>
        </form>
      </BoxedElement>
    </>
  )
}

export default EditSubscription