import React from 'react';
import NavLink from '../../Components/NavLink';
import BoxedElement from '../BoxedElement';

const HomeMenuGroup = ({group}) => {

  return (
    <BoxedElement className={'w-1/4 mr-2 pb-3'}>
      <div className='flex text-slate font-bold px-3 py-4 items-center'>
        <i className='mr-1 w-[20px]'>
          <div dangerouslySetInnerHTML={{__html: group.icon}} className='home-menu-icon'/>
        </i>
        {group.name}
      </div>
      {group.childrens && 
        <div>
          {
            group.childrens.map((child, j) => {
              var hrefChild = child.href;
              return (
                <NavLink 
                  className='pl-[40px] text-sm !text-black !min-h-0 !py-1'
                  key={j} 
                  to={hrefChild} 
                  allowedRoles={child.allowed}
                >
                  {child.name}
                </NavLink>
              )
            })
          }
        </div>
      }
    </BoxedElement>
  )
}

export default HomeMenuGroup;