//import { Link, usePage } from 'react';
import { Link } from 'react-router-dom';

export default function TransparentButton({ active = false, className = '', children, allowedRoles, ...props }) {
    //const { auth } = usePage().props;

    return (
        // !allowedRoles || auth?.roles?.find(role => allowedRoles?.includes(role)) ?
        // <Link
        //     {...props}
        //     className={
        //         'flex items-center content-center px-4 py-2 text-xs text-center rounded-md font-normal border uppercase font-semibold text-slate-900 leading-5 transition duration-150 ease-in-out focus:outline-none tracking-widest ' +
        //         (active
        //             ? 'bg-slate-800 '
        //             : 'hover:border-gray-300 focus:border-gray-300 ') +
        //         className
        //     }
        // >
        //     {children}
        // </Link>
        // : <></>

        <Link
            {...props}
            className={
                'flex items-center content-center px-4 py-2 text-xs text-center rounded-md font-normal border uppercase font-semibold text-slate-900 leading-5 transition duration-150 ease-in-out focus:outline-none tracking-widest ' +
                (active
                    ? 'bg-slate-800 '
                    : 'hover:border-gray-300 focus:border-gray-300 ') +
                className
            }
        >
            {children}
        </Link>
    );
}