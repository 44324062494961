import BoxedElement from "Components/BoxedElement";
import BoxedElementHeader from "Components/BoxedElementHeader";

import DeleteUserForm from './Profile/Partials/DeleteUserForm';
import UpdatePasswordForm from './Profile/Partials/UpdatePasswordForm';
import UpdateProfileInformationForm from './Profile/Partials/UpdateProfileInformationForm';

import PrimaryButton from "Components/Buttons/PrimaryButton";
import { useState, useEffect } from "react";
import useApi from 'Hooks/useApi';
import Modal from 'Components/Modal';
import Loader from 'Components/Loader';
import UserSubscription from "./Subscription/Index";
import { useOutletContext } from 'react-router-dom';

const Settings = () => {
  const [showSubscriptionChange, setShowSubscriptionChange] = useState(false);
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [setTitle] = useOutletContext();
  const api = useApi();

  const subscriptionChange = () => {
    setShowSubscriptionChange(true);
  };

  const closeModal = () => {
    setShowSubscriptionChange(false);
  };

  const getData = async () => {
    try {
      const response = await api.get('/settings/profile/');
      setData(response.data);
    } catch (err) {
      console.error(err);
    }
    
    setLoadingData(false);
  }

  useEffect(() => {
    setTitle('Настройки');
    getData();
  });

  return (
    <>
      {!loadingData ?
        <>
          <div className="flex">
            <BoxedElement className="w-1/2 mb-5">
              <BoxedElementHeader>Профиль</BoxedElementHeader>
              <UpdateProfileInformationForm className="p-5"/>
            </BoxedElement>
            <BoxedElement className="w-1/2 ml-5 mb-5">
              <BoxedElementHeader>Обновить пароль</BoxedElementHeader>
              <UpdatePasswordForm className="p-5" />
            </BoxedElement>
          </div>
          <div className="flex">
            <BoxedElement className="w-1/2 mb-5">
              <BoxedElementHeader>Подписка</BoxedElementHeader>
              <div className="p-5">
                <div>{data?.subscription}</div>
                <PrimaryButton className='mt-5 block' onClick={subscriptionChange}>Изменить</PrimaryButton>
                <Modal show={showSubscriptionChange} onClose={closeModal}>
                  <UserSubscription subscriptions={data.subscriptions} userSubscription={data.subscription} setShowSubscriptionChange={setShowSubscriptionChange}/>
                </Modal>
              </div>
            </BoxedElement>
            <BoxedElement className="w-1/2 mb-5 ml-5">
              <BoxedElementHeader>Удалить аккаунт</BoxedElementHeader>
              <DeleteUserForm className="p-5" />
            </BoxedElement>
          </div>
        </>
      :
        <Loader></Loader>
      }
    </>
  )
}

export default Settings