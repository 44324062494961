import { useState } from 'react';

const DragAndDropInput = ({className, inputName, handleChangeProp, accept=".doc,.docx", multipleProp=false}) => {
  const [dragActive, setDragActive] = useState(false);

  const handleDrop = function(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if(e.dataTransfer.files.length == 1) {
        handleChangeProp(e.dataTransfer.files[0]);
        e.target.parentNode.getElementsByTagName('label')[0].innerHTML = e.dataTransfer.files[0].name;
      } else {
        e.target.parentNode.getElementsByTagName('label')[0].innerHTML = 'Файлов: ' + e.dataTransfer.files.length;
      }
    }
  };
  
  const handleDrag = function(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleChange = (e) => {
    handleChangeProp(e.target.files[0]);
    e.target.nextElementSibling.innerHTML = e.target.files[0].name;
  }

  const handleMultiChange = (e) => {
    handleChangeProp(e.target.files);
    if(e.target.files.length > 1) {
      e.target.nextElementSibling.innerHTML = 'Файлов: ' + e.target.files.length;
    } else {
      e.target.nextElementSibling.innerHTML = e.target.files[0].name;
    }
    
  }

  return (
    <div className={className} onDragEnter={handleDrag}>
      <input 
        name={inputName} 
        type="file" 
        id={inputName} 
        className='text-slate-400 mb-4 dnd-input' 
        onChange={multipleProp ? handleMultiChange : handleChange} 
        accept={accept}
        multiple={multipleProp}
      />
      <label className={`label-file-upload ${dragActive ? "drag-active" : "" }`} htmlFor={inputName}>
        <div className='text-center'>Перетащите файлы сюда</div> 
      </label>
      { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
    </div>
  )
}

export default DragAndDropInput