import { useState, useEffect } from 'react';
import InputError from 'Components/InputError';
import InputLabel from 'Components/InputLabel';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { axiosPrivate } from 'Api/axios';
import { useForm } from "react-hook-form";
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import useAuth from 'Hooks/useAuth';
import Loader from 'Components/Loader';

const LOGIN_URL = '/token/';
const Login = () => {
    const [loginErrors, setLoginErrors] = useState('');
    const auth = useAuth();
    const navigate = useNavigate();
    const [setTitle] = useOutletContext();
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
    } = useForm();

    const submit = (data) => {
        setLoading(true);
        let postData = {
            "grant_type":"password",
            "client_id":"1",
            ...data
        }
        axiosPrivate.post(LOGIN_URL, postData).then((response) => {
            auth.loginAction(response.data);
            setLoading(false);
            navigate('/');
        }).catch(function (error) {
            console.log(error);
            setLoginErrors('Неверный логин или пароль');
            setLoading(false);
        });
    };

    useEffect(() => {
        setTitle('Вход');
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(submit)} className="login">
                <div>
                    <InputLabel htmlFor="email" value="Email" />
                    <input
                        id="email"
                        type="text"
                        name="email"
                        className="mt-1 block w-full"
                        autoComplete="email"
                        {...register("email")}
                    />
                </div>

                <div className="mt-4">
                    <InputLabel htmlFor="password" value="Пароль" />
                    <input
                        id="password"
                        type="password"
                        name="password"
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                        {...register("password")}
                    />
                </div>

                <div className="block mt-4">
                    <label className="flex items-center">
                        <input
                            type="checkbox"
                            name="remember"
                            {...register("remember")}
                        />
                        <span className="ms-2 text-sm text-gray-600">Запомнить меня</span>
                    </label>
                </div>

                {loginErrors && 
                <InputError message={loginErrors} className="mt-2" />}
                
                <div className="flex items-center justify-between mt-4 gap-1">
                    <PrimaryButton className="w-full mb-4">
                        Войти
                    </PrimaryButton>
                </div>
            </form>
            
            <Link
                to='/forgot_password'
                className="underline text-sm block text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Забыли пароль?
            </Link>
            <Link
                to='/register'
                className="underline text-sm block text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Регистрация
            </Link>
            {loading && <Loader></Loader>}
        </>
    );
}

export default Login;
