import { useRef } from 'react';
import InputError from 'Components/InputError';
import InputLabel from 'Components/InputLabel';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { Transition } from '@headlessui/react';

export default function UpdatePasswordForm({ className = '' }) {
    const passwordInput = useRef();
    const currentPasswordInput = useRef();

    const updatePassword = (e) => {
        e.preventDefault();
    };

    return (
        <section className={className}>
            <form onSubmit={updatePassword} className="space-y-6">
                <div>
                    <InputLabel htmlFor="current_password" value="Текущий пароль" />

                    <input
                        id="current_password"
                        ref={currentPasswordInput}
                        type="password"
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                    />

                    <InputError message={``} className="mt-2" />
                </div>

                <div>
                    <InputLabel htmlFor="password" value="Новый пароль" />

                    <input
                        id="password"
                        ref={passwordInput}
                        type="password"
                        className="mt-1 block w-full"
                        autoComplete="new-password"
                    />

                    <InputError message={``} className="mt-2" />
                </div>

                <div>
                    <InputLabel htmlFor="password_confirmation" value="Подтверждение нового пароля" />

                    <input
                        id="password_confirmation"
                        type="password"
                        className="mt-1 block w-full"
                        autoComplete="new-password"
                    />

                    <InputError message={``} className="mt-2" />
                </div>

                <div className="flex items-center gap-4">
                    <PrimaryButton>Сохранить</PrimaryButton>

                    <Transition
                        show={``}
                        enter="transition ease-in-out"
                        enterFrom="opacity-0"
                        leave="transition ease-in-out"
                        leaveTo="opacity-0"
                    >
                        <p className="text-sm text-gray-600">Сохранено</p>
                    </Transition>
                </div>
            </form>
        </section>
    );
}
