import { Link } from 'react-router-dom';

export default function NavLink({ active = false, className = '', children, allowedRoles, ...props }) {
    return (
        // !allowedRoles || auth?.roles?.find(role => allowedRoles?.includes(role)) ?
        // <Link
        //     {...props}
        //     className={
        //         'inline-flex items-center py-3 px-4 min-h-12 text-m w-full font-normal text-slate-100 leading-5 transition duration-150 ease-in-out focus:outline-none ' +
        //         (active
        //             ? 'bg-slate-800 '
        //             : 'border-transparent hover:border-gray-300 focus:border-gray-300 ') +
        //         className
        //     }
        // >
        //     {children}
        // </Link>
        // : <></>
        <Link
            {...props}
            className={
                'inline-flex items-center py-3 px-4 min-h-12 text-m w-full font-normal text-slate-100 leading-5 transition duration-150 ease-in-out focus:outline-none ' +
                (active
                    ? 'bg-slate-800 '
                    : 'border-transparent hover:border-gray-300 focus:border-gray-300 ') +
                className
            }
        >
            {children}
        </Link>
    );
}
