import React from "react";
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Link to='/' className=' h-full flex items-center text-white transition-all duration-25 px-10'>
      <img src="/logo.png" className='object-scale-down h-3/4 object-center'/> 
    </Link>
  )
}

export default Logo