import React from 'react';
import { useState, useEffect } from "react";
import useApi from 'Hooks/useApi';
import { useOutletContext, useNavigate } from 'react-router-dom';
import Loader from 'Components/Loader';
import BoxedElement from 'Components/BoxedElement';
import TablePagination from 'Components/Table/TablePagination';

const Notifications = () => {
  const api = useApi();
  const [setTitle] = useOutletContext();
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  const getData = async () => {
    try {
      let pageUrl = (page > 1) ? '?page='+page : '';
      const response = await api.get('notifications/'+pageUrl);
      setData(response.data);
    } catch (err) {
      console.error(err);
    }
    
    setLoadingData(false);
  }

  const showNotification = (id) => {
    navigate("/notifications/"+id);
  }

  useEffect(() => {
    setTitle('Оповещения');
    getData();
  }, [page]);

  return (
    <>
      {!loadingData ?
          <>
            <BoxedElement className={'p-5'}>
              {
                data.result.map((item) => 
                  <div className={`notification-item cursor-pointer ${item.have_read ? '': 'unreaded'}`} onClick={() => showNotification(item.id)}>
                    <div>{item.text}</div>
                    <span>{new Date(item.date).toLocaleDateString() + ' ' + new Date(item.date).toLocaleTimeString()}</span>
                  </div>
                )
              }
            </BoxedElement>
            {data?.pagination && data?.pagination.pages != 1 && <TablePagination pages={data?.pagination?.pages} page={page} setPage={setPage}/>}
          </>
        :
          <Loader></Loader>
      }
    </>
  )
}

export default Notifications