export const AdminMenuItems = [
  {
    name: 'Пользователи',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" height="14" width="12.25" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" fill="#fff"/></svg>`,
    childrens: [
      {
        name: 'Список',
        href: 'admin/users',
        allowed: ['admin', 'manager'],
      },
      {
        name: 'Роли',
        href: 'admin/roles',
        allowed: ['admin']
      },
      {
        name: 'Разрешения',
        href: 'admin/permissions',
        allowed: ['admin']
      }
    ]
  },
  {
    name: 'Сервисы',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" height="14" width="16" viewBox="0 0 640 512"><path d="M256 64H384v64H256V64zM240 0c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48h48v32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h96v32H80c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H240c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H192V288H448v32H400c-26.5 0-48 21.5-48 48v96c0 26.5 21.5 48 48 48H560c26.5 0 48-21.5 48-48V368c0-26.5-21.5-48-48-48H512V288h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H352V192h48c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H240zM96 448V384H224v64H96zm320-64H544v64H416V384z" fill="#fff"/></svg>`,
    childrens: [
      {
        name: 'Список',
        href: 'admin/services',
        allowed: ''
      },
      {
        name: 'Cтатистика',
        href: 'admin/services/statistic',
        allowed: ''
      },
      {
        name: 'Подписки',
        href: 'admin/subscriptions',
        allowed: ''
      }
    ]
  }
]