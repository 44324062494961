import { Outlet, useOutletContext } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

const AdminRoute = () => {
  const auth = useAuth();
  const [setTitle] = useOutletContext();
  return (
    auth.user && auth?.user.is_staff
      ? <Outlet context={[setTitle]}/>
      : <p>Нет прав для просмотра данной страницы!</p>
  );
};

export default AdminRoute;