import Table from 'Components/Table/TableWrap';

const ServicesStatistic = () => {
  return (
    <>
      <Table 
        filters={
          {
            id: 'id',
            alias: 'Сервис', 
            user: 'ID пользователя',
            name: 'Имя пользователя',
            ip: 'IP',
            referer: 'Referer',
            user_agent: 'User Agent'
          } 
        }
        columns={
          { 
            id: {
              name: 'id',
              orderable: true,
            },
            service: {
              name: 'Сервис',
              orderable: true,
            }, 
            user: {
              name: 'Пользователь',
              orderable: true,
            }, 
            ip: {
              name: 'IP', 
              orderable: true,
            },
            referer: {
              name: 'Referer',
              orderable: true,
            },
            description: {
              name: 'Описание', 
              orderable: true,
            },
            date: {
              name: 'Дата', 
              orderable: true,
            },
            user_agent: {
              name: 'User Agent', 
              orderable: true,
            },
          }
        }

        dataUrl = 'admin/statistics/'
        cells = {['id', 'service', 'user', 'ip', 'referer', 'description', 'date', 'user_agent']}
      />
    </>
  )
}
export default ServicesStatistic