import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosPrivate } from 'Api/axios';
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const loginAction = async (data) => {
    axiosPrivate.get('/user/', {
      headers: {
        'Authorization': `Bearer ${data.access_token}`,
      }
    }).then((response) => {
      setUser(response.data);
    }).catch(function (error) {
      logOut();
      console.log(error);
    });

    setToken(data.access_token);
    localStorage.setItem("refresh", data.refresh_token);
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("refresh");
    navigate("/login");
  };

  return <AuthContext.Provider value={{ token, user, loginAction, logOut, setUser }}>{children}</AuthContext.Provider>;
};

export default AuthContext;