import { createContext, useState } from "react";
import Notification from 'Components/Notification';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [message, setMessage] = useState(null);

  return (
    <NotificationContext.Provider value={{ message, setMessage }}>
      {children}
      <Notification />
    </NotificationContext.Provider>
  )
}

export default NotificationContext;